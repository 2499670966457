import { PhoneNumberCountriesEnum } from './enum'

export const getLocalStorageItem = (key) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage.getItem(key)
  }
  return null // Return null if localStorage is not available (SSR)
}

// Safely set an item in localStorage
export const setLocalStorageItem = (key, value) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.setItem(key, value)
  }
}

// Safely clear all localStorage
export const clearLocalStorage = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.clear()
  }
}

// Phone number fomatter
const countryPhoneFormats = {
  [PhoneNumberCountriesEnum.USA]: { format: '(XXX) XXX-XXXX', maxLength: 14 },
  [PhoneNumberCountriesEnum.MN]: { format: 'XXXX-XXXX', maxLength: 9 },
}
export const phoneNumberFormatter = (numbers = '', country = PhoneNumberCountriesEnum.USA) => {
  numbers = numbers.replace(/\D/g, '')

  const { format, maxLength } = countryPhoneFormats[country]
  let formattedNumber = ''
  let numberIndex = 0

  for (const char of format) {
    if (numberIndex >= numbers.length) break

    if (char === 'X') {
      formattedNumber += numbers[numberIndex]
      numberIndex++
    } else {
      formattedNumber += char
    }
  }

  return formattedNumber.slice(0, maxLength)
}

// Currency formatter
export const currencyFormatter = (amount, locale = 'en-US', currency = 'USD') => {
  if (amount === null || isNaN(amount)) return 'N/A'

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(amount)
}
