import React, { useState, useEffect, useCallback } from 'react'
import { faEye, faChevronLeft, faLock, faSave } from '@fortawesome/free-solid-svg-icons'
import { AddUserModal, ChangePinModal, UserInfo } from '../../../../components/admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import client from '../../../../api/client'
import { useParams } from '@reach/router'
import { navigate } from 'gatsby'
import { Row, Col, Input, Table, Button, Form, Divider, notification } from 'antd'
export const UserInfoContext = React.createContext()
const tableTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 24,
}
const UserDetails = () => {
  const userId = useParams()?.userId
  const [userInfoFormRef] = Form.useForm()
  const [addUserFormRef] = Form.useForm()
  const [newPinFormRef] = Form.useForm()
  const [userDetails, setUserDetails] = useState({})
  const [accountId, setAccountId] = useState(null)
  const [addUserModalVisible, setAddUserModalVisible] = useState(false)
  const [addUserType, setAddUserType] = useState('sender')
  const [tableLoading, setTableLoading] = useState(true)
  const [isEditting, setIsEditting] = useState(false)
  const [linkedUserDetails, setLinkedUserDetails] = useState({})
  const [sendersInfo, setSendersInfo] = useState({
    tableData: [],
    dataCount: 0,
  })
  const [receiversInfo, setReceiversInfo] = useState({
    tableData: [],
    dataCount: 0,
  })
  const [requestDataSender, setRequestDataSender] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sorter: {
      field: 'firstName',
      order: 'ascend',
    },
  })
  const [requestDataReceiver, setRequestDataReceiver] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sorter: {
      field: 'firstName',
      order: 'ascend',
    },
  })
  const [changePin, setChangePin] = useState(false)

  const fetchSendersList = useCallback(
    (paramAccountId) => {
      client
        .post(`/api/accounts/${paramAccountId ? paramAccountId : accountId}/senders/paginated`, {
          ...requestDataSender,
        })
        .then(async (res) => {
          setSendersInfo({
            tableData: res.data.data,
            dataCount: res.data.total,
          })
          setTableLoading(false)
        })
        .catch((err) => console.error({ err }))
    },
    [accountId, requestDataSender],
  )

  const fetchReceiversList = useCallback(
    (paramAccountId) => {
      client
        .post(`/api/accounts/${paramAccountId ? paramAccountId : accountId}/receivers/paginated`, {
          ...requestDataReceiver,
        })
        .then(async (res) => {
          setReceiversInfo({
            tableData: res.data.data,
            dataCount: res.data.total,
          })
          setTableLoading(false)
        })
        .catch((err) => console.error({ err }))
    },
    [accountId, requestDataReceiver],
  )

  useEffect(() => {
    client
      .get(`/api/accounts/senders/${userId}`)
      .then(async (res) => {
        setUserDetails(res.data)
        setAccountId(res.data.accountId)
        userInfoFormRef.setFieldsValue({ ...res.data })
        fetchSendersList(res.data.accountId)
        fetchReceiversList(res.data.accountId)
      })
      .catch((err) => console.error({ err }))
  }, [userId, userInfoFormRef, fetchReceiversList, fetchSendersList])

  const editSenderInfo = (values) => {
    const endpoint = `/api/accounts/senders/${userId}`
    let data = { ...userDetails, ...values }

    client
      .put(endpoint, data)
      .then(async (res) => {
        notification.success({
          message: 'Илгээгчийн мэдээллийг амжилттай солилоо!',
        })
      })
      .catch((err) => console.error({ err }))
  }

  const resetPin = (values) => {
    const endpoint = `/api/admin/accounts/${accountId}/resetpincode`
    client
      .put(endpoint, { ...values })
      .then(async (res) => {
        if (res.status === 200) {
          setChangePin(false)
          notification.success({
            message: 'Хэрэглэгчийн пин кодыг амжилттай солилоо!',
          })
        }
      })
      .catch((err) => console.error({ err }))
  }

  const addUser = (values) => {
    let endpoint = ''
    let msg = ''
    let data = { ...values }
    data.phoneNumber = values.phoneNumber ? values.phoneNumber.replace(/\D/g, '').substring(0, 10) : ''
    if (addUserType === 'sender') {
      endpoint = `/api/accounts/${accountId}/senders`
      msg = 'Илгээгчийг'
    } else if (addUserType === 'receiver') {
      endpoint = `/api/accounts/${accountId}/receivers`
      msg = 'Хүлээн авагчийг'
    }
    client
      .post(endpoint, data)
      .then(async (res) => {
        notification.success({ message: `${msg} амжилттай бүртгэлээ!` })
        if (addUserType === 'sender') fetchSendersList()
        else if (addUserType === 'receiver') fetchReceiversList()
        setAddUserModalVisible(false)
      })
      .catch((err) => console.error({ err }))
  }

  const formatMNPhone = (number) => {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)

    const first4 = cleaned.substring(0, 4)
    const last4 = cleaned.substring(4, 8)

    let ret = ''
    if (cleaned.length > 4) ret = `${first4} ${last4}`
    else if (cleaned.length > 0) ret = `${first4}`
    return ret
  }

  useEffect(() => {
    if (!addUserModalVisible) setIsEditting(false)
  }, [addUserModalVisible])

  const editUser = (values) => {
    let endpoint = ''
    let whichUser = ''
    if (addUserType === 'receiver') {
      endpoint = `/api/accounts/receivers/${linkedUserDetails.id}`
      whichUser = 'Хүлээн авагчийн'
    } else {
      endpoint = `/api/accounts/senders/${linkedUserDetails.id}`
      whichUser = 'Илгээгчийн'
    }

    const data = {
      ...values,
      phoneNumber: values.phoneNumber ? values.phoneNumber.replace(/\D/g, '').substring(0, 10) : '',
    }
    client
      .put(endpoint, data)
      .then(async (res) => {
        setAddUserModalVisible(false)
        if (addUserType === 'receiver') fetchReceiversList()
        else fetchSendersList()

        notification.success({
          message: `${whichUser} мэдээллийг амжилттай солилоо!`,
        })
      })
      .catch((err) => console.error({ err }))
      .then(() => setIsEditting(false))
  }

  const fetchLinkedUserInfo = async (details) => {
    await setLinkedUserDetails(details)
    setAddUserModalVisible(true)
    addUserFormRef.setFieldsValue(details)
  }

  const senderColumns = [
    {
      title: 'Нэр',
      className: 'text-center',
      render: (record) => `${record.firstName} ${record.lastName ? record.lastName : ''}`,
    },
    {
      title: 'Утас',
      className: 'text-center',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Email',
      className: 'text-center',
      dataIndex: 'email',
    },
    {
      title: 'Хаяг',
      className: 'text-center',
      render: (record) => (!record.province ? record.address : `${record.address ? `${record.address}` : ''}`),
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      render: (record) => (
        <Button
          className="btn-sb btn-sb-primary"
          onClick={async () => {
            setAddUserType('sender')
            await setIsEditting(true)
            fetchLinkedUserInfo(record)
          }}
        >
          <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
        </Button>
      ),
    },
  ]
  const receiverColumns = [
    {
      title: 'Нэр',
      className: 'text-center',
      render: (record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Утас',
      className: 'text-center',
      dataIndex: 'phoneNumber',
      render: (phone) => formatMNPhone(phone),
    },
    {
      title: 'Email',
      className: 'text-center',
      dataIndex: 'email',
    },
    {
      title: 'Хаяг',
      className: 'text-center',
      render: (record) =>
        !record.province
          ? record.address
          : `${record.address ? `${record.address}, ` : ''}${record.subdistrict}, ${record.district}, ${record.province}`,
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      render: (record) => (
        <Button
          className="btn-sb btn-sb-primary"
          onClick={async () => {
            setAddUserType('receiver')
            await setIsEditting(true)
            fetchLinkedUserInfo(record)
          }}
        >
          <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
        </Button>
      ),
    },
  ]

  return (
    <>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Button type="link" style={{ fontWeight: 500, fontSize: 20, padding: 0 }} onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} size="sm" className="me-2" />
            Буцах
          </Button>
        </Col>
        <Col flex="auto">
          <h4>
            {userDetails.firstName} {userDetails.lastName} дэлгэрэнгүй мэдээлэл
          </h4>
        </Col>
        <Col flex="250px" className="ms-2">
          <Button className="btn-sb btn-sb-primary sb-shadow w-100" onClick={() => setChangePin(true)}>
            <FontAwesomeIcon icon={faLock} size="sm" className="me-2" />
            Пин код солих
          </Button>
        </Col>
        <Col flex="250px" className="ms-2">
          <Button className="ant-btn btn-sb btn-sb-primary sb-shadow w-100" form="user-details" htmlType="submit">
            <FontAwesomeIcon icon={faSave} size="sm" className="me-2" />
            Хадгалах
          </Button>
        </Col>
        <Col span={24}>
          <UserInfoContext.Provider value={{ formRef: userInfoFormRef, editSenderInfo }}>
            <UserInfo />
          </UserInfoContext.Provider>
        </Col>
        <Divider />
        <Col span={24}>
          <Row gutter={[0, 8]}>
            <Col flex="auto" style={tableTitleStyle}>
              <span>Илгээгчийн жагсаалт</span>
            </Col>
            <Col flex="250px" className="ms-2">
              <Input.Search
                className="sb-search sb-shadow"
                placeholder="Хайх"
                onSearch={(value) =>
                  setRequestDataSender({
                    ...requestDataSender,
                    search: value,
                    pagination: {
                      ...requestDataSender.pagination,
                      current: 1,
                    },
                  })
                }
              />
            </Col>
            <Col flex="250px" className="ms-2">
              <Button
                className="ant-btn btn-sb btn-sb-primary sb-shadow w-100"
                onClick={async () => {
                  await setAddUserType('sender')
                  setAddUserModalVisible(true)
                }}
              >
                Илгээгч бүртгэх
              </Button>
            </Col>
          </Row>
          <Row gutter={[0, 8]}>
            <Table
              className="sb-table w-100 mt-3"
              dataSource={sendersInfo.tableData}
              columns={senderColumns}
              rowKey={(record) => record.id}
              locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
              loading={tableLoading}
              pagination={{
                total: sendersInfo.dataCount,
                current: requestDataSender.pagination.current,
                pageSize: requestDataSender.pagination.pageSize,
                onChange: async (page, pageSize) => {
                  setTableLoading(true)
                  setRequestDataSender({
                    ...requestDataSender,
                    pagination: {
                      current: page,
                      pageSize,
                    },
                  })
                },
              }}
            />
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 8]}>
            <Col flex="auto" style={tableTitleStyle}>
              <span>Хүлээн авагчийн жагсаалт</span>
            </Col>
            <Col flex="250px" className="ms-2">
              <Input.Search
                className="sb-search sb-shadow"
                placeholder="Хайх"
                onSearch={(value) =>
                  setRequestDataReceiver({
                    ...requestDataReceiver,
                    search: value,
                    pagination: {
                      ...requestDataReceiver.pagination,
                      current: 1,
                    },
                  })
                }
              />
            </Col>
            <Col flex="250px" className="ms-2">
              <Button
                className="ant-btn btn-sb btn-sb-primary sb-shadow w-100"
                onClick={async () => {
                  await setAddUserType('receiver')
                  setAddUserModalVisible(true)
                }}
              >
                Хүлээн авагч бүртгэх
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Table
              className="sb-table w-100 mt-3"
              dataSource={receiversInfo.tableData}
              columns={receiverColumns}
              rowKey={(record) => record.id}
              locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
              loading={tableLoading}
              pagination={{
                total: receiversInfo.dataCount,
                current: requestDataReceiver.pagination.current,
                pageSize: requestDataReceiver.pagination.pageSize,
                onChange: async (page, pageSize) => {
                  setTableLoading(true)
                  setRequestDataReceiver({
                    ...requestDataReceiver,
                    pagination: {
                      current: page,
                      pageSize,
                    },
                  })
                },
              }}
            />
          </Row>
        </Col>
      </Row>
      {changePin && <ChangePinModal modalVisible={changePin} closeModal={() => setChangePin(false)} formRef={newPinFormRef} onSubmit={resetPin} />}
      {addUserModalVisible && (
        <AddUserModal
          modalVisible={addUserModalVisible}
          isReceiver={addUserType === 'receiver'}
          isSender={addUserType === 'sender'}
          closeModal={() => setAddUserModalVisible(false)}
          formRef={addUserFormRef}
          modalTitle={addUserType === 'sender' ? 'Илгээгчийн мэдээлэл' : 'Хүлээн авагчийн мэдээлэл'}
          showUSAAddress={addUserType === 'sender'}
          showMGLAddress={addUserType === 'receiver'}
          showRole={false}
          isEditting={isEditting}
          userDetails={linkedUserDetails}
          onSubmit={isEditting ? editUser : addUser}
        />
      )}
    </>
  )
}

export default UserDetails
